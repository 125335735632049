<template>
  <div class="w-full h-screen shadow-lg z-10 relative overflow-auto px-6">
    <div
      v-if="!loading"
      class="container page pt-20 lg:pt-16 pb-24 px-6 lg:px-0"
    >
      <h1>{{ labels.glossary }}</h1>

      <div v-for="entry in orderedGlossary" :key="entry._id">
        <div
          class="cursor-pointer text-xl hover:text-gray-600 border-b border-gray-300 py-2"
          @click.prevent="applyEntry(entry._id)"
        >
          {{ entry.langs[lang.abbreviation] }}
        </div>
      </div>
    </div>

    <div v-else class="w-full h-full flex justify-center items-center">
      <DotLoading dotColor="#25899b" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import labels from "@/i18n/labels";

import DotLoading from "@/components/DotLoading";

export default {
  name: "Glossary",
  components: {
    DotLoading
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage,
      entries: state => state.Glossary.list,
      selectedEntry: state => state.Glossary.selectedEntry
    }),

    labels() {
      return labels[this.lang.abbreviation]
        ? labels[this.lang.abbreviation]
        : labels["en"];
    },

    orderedGlossary() {
      if (this.entries.length > 0) {
        return [...this.entries].sort((a, b) =>
          a.titleAdmin.localeCompare(b.titleAdmin, "pt", {
            ignorePunctuation: true
          })
        );
      } else {
        return [];
      }
    }
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Glossary/list");
    this.loading = false;
  },
  async mounted() {},
  methods: {
    applyEntry(entryId) {
      this.$store.commit("Glossary/SET_SELECTED_ENTRY", {
        entryId: entryId
      });
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 42px;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-indent: 4rem;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  blockquote p,
  ul li p,
  ol li p {
    text-indent: 0;
  }

  blockquote {
    padding: 1rem 0 1rem 4rem;
    font-size: 1.3rem;

    p {
      font-size: 1.3rem;
    }
  }

  ul {
    list-style: circle;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  ol {
    list-style: decimal;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  table {
    width: calc(90% - 4rem);
    margin: 2rem 0 2rem 4rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid #ccc;
    padding: 6px;

    p {
      font-size: 1rem;
      text-indent: 0;
    }
  }

  th,
  th p {
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}

.glossaryEntry {
  background: #95ddea;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: #66c7d9;
  }
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  background: #000;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
