<template>
  <div class="container pr-2 my-4">
    <div class="my-6 flex justify-end">
      <router-link to="/gerenciador/paginas/adicionar" class="button"
        >Nova página</router-link
      >
    </div>

    <ul class="list w-full bg-white">
      <li v-for="page in orderedPages" :key="page._id">
        <div>
          {{ page.order }} <strong class="pl-6">{{ page.titleAdmin }}</strong>
          <span class="ml-5 bg-gray-200 text-xs px-2 py-1 rounded">{{
            pageType[page.type]
          }}</span>
        </div>
        <div class="flex">
          <router-link
            :to="`/gerenciador/paginas/${page._id}`"
            class="button mr-2 green"
          >
            Editar
          </router-link>
          <div class="button red" @click.prevent="remove(page._id)">
            Excluir
          </div>
        </div>
      </li>
      <li v-if="pages.length == 0">Nenhuma página encontrada</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PagesList",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      pages: state => state.Pages.list
    }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
    orderedPages() {
      if (this.pages.length > 0) {
        return [...this.pages].sort((a, b) => a.order - b.order);
      } else {
        return [];
      }
    },

    pageType() {
      return {
        home: "Não deletar!",
        content: "Conteúdo de destaque",
        tables: "Junto das tabelas",
        institutional: "Institucional"
      };
    }
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Pages/list");
  },
  async mounted() {},
  methods: {
    async remove(pageId) {
      const shouldRemove = confirm("Deseja mesmo apagar essa página?");
      if (shouldRemove == true) {
        await this.$store.dispatch("Pages/remove", pageId);
        await this.$store.dispatch("Pages/list");
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
