<template>
  <div
    class="w-full h-screen shadow-lg z-10 relative overflow-auto"
    style="background-color: #2f4748;"
  >
    <div
      v-if="!loading"
      class="container flex flex-col w-full h-screen justify-center items-center page pt-20 lg:pt-16 pb-24 px-6 lg:px-0"
    >
      <div class="flex">
        <ClassSign
          :sign="getSign(1)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(1)._id)"
        />
        <ClassSign
          :sign="getSign(47)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(47)._id)"
        />
        <ClassSign
          :sign="getSign(61)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(61)._id)"
        />
        <ClassSign
          :sign="getSign(66)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(66)._id)"
        />
      </div>

      <div class="flex">
        <ClassSign
          :sign="getSign(13)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(13)._id)"
        />
        <ClassSign
          :sign="getSign(53)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(53)._id)"
        />
        <ClassSign
          :sign="getSign(63)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(63)._id)"
        />
      </div>

      <div class="flex">
        <ClassSign
          :sign="getSign(19)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(19)._id)"
        />
        <ClassSign
          :sign="getSign(55)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(55)._id)"
        />
      </div>

      <div class="flex">
        <ClassSign
          :sign="getSign(21)"
          :c1="c1"
          :c2="c2"
          :c3="c3"
          :lang="lang.abbreviation"
          @click="exploreClass(getSign(21)._id)"
        />
      </div>
    </div>

    <div v-else class="w-full h-full flex justify-center items-center">
      <DotLoading dotColor="#25899b" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DotLoading from "@/components/DotLoading";
import ClassSign from "@/components/ClassSign";

export default {
  name: "TenClasses",
  components: {
    DotLoading,
    ClassSign
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      page: null,
      langs: null,
      classes: []
    };
  },
  computed: {
    ...mapState({
      lang: state => state.Settings.activeLanguage,
      glossary: state => state.Glossary.list,
      selectedEntry: state => state.Glossary.selectedEntry,
      c1: state => state.Settings.c1,
      c2: state => state.Settings.c2,
      c3: state => state.Settings.c3
    })
  },
  watch: {},
  async created() {
    // const id = this.$route.params.id;
    const classes = await this.$store.dispatch("Classes/fromList", [
      1,
      13,
      19,
      21,
      47,
      53,
      55,
      61,
      63,
      66
    ]);
    if (classes) {
      this.classes = classes;
    }

    this.loading = false;
  },
  async mounted() {
    if (this.$route.params.id) {
      const active = this.classes.find(
        item =>
          item._id == this.$route.params.id ||
          item.number == this.$route.params.id
      );
      if (active) {
        this.$store.commit("Classes/SET_ACTIVE", active._id);
      }
    }
  },
  methods: {
    getSign(signNumber) {
      return this.classes.find(sign => sign.number == signNumber);
    },
    exploreClass(signId) {
      console.log(signId);
      this.$router.push({
        path: this.$route.path,
        query: {
          classSign: signId
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
