<template>
  <div
    class="w-full h-screen shadow-lg z-10 relative overflow-auto flex"
    style="background-color: #2f4748;"
  >
    <div id="table66" class="tableArea h-screen flex-shrink-0">
      <div
        v-if="!loading"
        class="tableArea relative flex flex-wrap justify-center items-center page pt-20 lg:pt-16 pb-24 px-6 lg:px-0"
      >
        <h1 class="text-white font-black text-5xl absolute point3">2</h1>

        <div class="tableArea" v-if="periods && phases">
          <div class="arrow periods arrow-grounding">
            {{
              periods.grounding[lang.abbreviation]
                ? periods.grounding[lang.abbreviation].title
                : periods.grounding["en"].title
            }}
          </div>
          <div class="arrow periods arrow-presentation">
            {{
              periods.presentation[lang.abbreviation]
                ? periods.presentation[lang.abbreviation].title
                : periods.presentation["en"].title
            }}
          </div>
          <div class="arrow periods arrow-representing">
            {{
              periods.representing[lang.abbreviation]
                ? periods.representing[lang.abbreviation].title
                : periods.representing["en"].title
            }}
          </div>
          <div class="arrow periods arrow-communicating">
            {{
              periods.communicating[lang.abbreviation]
                ? periods.communicating[lang.abbreviation].title
                : periods.communicating["en"].title
            }}
          </div>

          <div class="arrow phases arrow-perceiving">
            {{
              phases.perceiving[lang.abbreviation]
                ? phases.perceiving[lang.abbreviation].title
                : phases.perceiving["en"].title
            }}
          </div>
          <div class="arrow phases arrow-experiencing">
            {{
              phases.experiencing[lang.abbreviation]
                ? phases.experiencing[lang.abbreviation].title
                : phases.experiencing["en"].title
            }}
          </div>
          <div class="arrow phases arrow-understanding">
            {{
              phases.understanding[lang.abbreviation]
                ? phases.understanding[lang.abbreviation].title
                : phases.understanding["en"].title
            }}
          </div>
          <div class="arrow phases arrow-sharing">
            {{
              phases.sharing[lang.abbreviation]
                ? phases.sharing[lang.abbreviation].title
                : phases.sharing["en"].title
            }}
          </div>
          <div class="arrow phases down arrow-imagining">
            {{
              phases.imagining[lang.abbreviation]
                ? phases.imagining[lang.abbreviation].title
                : phases.imagining["en"].title
            }}
          </div>
          <div class="arrow phases down arrow-producing">
            {{
              phases.producing[lang.abbreviation]
                ? phases.producing[lang.abbreviation].title
                : phases.producing["en"].title
            }}
          </div>
          <div class="arrow phases down arrow-deliberating">
            {{
              phases.deliberating[lang.abbreviation]
                ? phases.deliberating[lang.abbreviation].title
                : phases.deliberating["en"].title
            }}
          </div>
          <div class="arrow phases down arrow-persuading">
            {{
              phases.persuading[lang.abbreviation]
                ? phases.persuading[lang.abbreviation].title
                : phases.persuading["en"].title
            }}
          </div>
        </div>

        <div class="tableArea w-full flex justify-center items-start">
          <h1 class="text-white font-black text-5xl absolute point1">1</h1>
          <ClassSign
            :sign="getSign(1)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(1)._id)"
          />
          <ClassSign
            :sign="getSign(4)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(4)._id)"
          />
          <ClassSign
            :sign="getSign(6)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(6)._id)"
          />
          <ClassSign
            :sign="getSign(27)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(27)._id)"
          />
          <ClassSign
            :sign="getSign(30)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(30)._id)"
          />
          <ClassSign
            :sign="getSign(32)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(32)._id)"
          />
          <ClassSign
            :sign="getSign(47)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(47)._id)"
          />
          <ClassSign
            :sign="getSign(50)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(50)._id)"
          />
          <ClassSign
            :sign="getSign(52)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(52)._id)"
          />
          <ClassSign
            :sign="getSign(61)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(61)._id)"
          />
          <ClassSign
            :sign="getSign(64)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(64)._id)"
          />
          <ClassSign
            :sign="getSign(66)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(66)._id)"
          />
          <h1 class="text-white font-black text-5xl absolute point2">3</h1>
        </div>

        <div class="tableArea w-full flex justify-center items-start">
          <ClassSign
            :sign="getSign(2)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(2)._id)"
          />
          <ClassSign
            :sign="getSign(5)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(5)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(28)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(28)._id)"
          />
          <ClassSign
            :sign="getSign(31)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(31)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(48)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(48)._id)"
          />
          <ClassSign
            :sign="getSign(51)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(51)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(62)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(62)._id)"
          />
          <ClassSign
            :sign="getSign(65)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(65)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(3)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(3)._id)"
          />
          <BlankClass />
          <BlankClass />
          <ClassSign
            :sign="getSign(29)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(29)._id)"
          />
          <BlankClass />
          <BlankClass />
          <ClassSign
            :sign="getSign(49)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(49)._id)"
          />
          <ClassSign
            :sign="getSign(56)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(56)._id)"
          />
          <ClassSign
            :sign="getSign(59)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(59)._id)"
          />
          <ClassSign
            :sign="getSign(63)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(63)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(7)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(7)._id)"
          />
          <ClassSign
            :sign="getSign(10)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(10)._id)"
          />
          <ClassSign
            :sign="getSign(12)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(12)._id)"
          />
          <ClassSign
            :sign="getSign(33)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(33)._id)"
          />
          <ClassSign
            :sign="getSign(36)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(36)._id)"
          />
          <ClassSign
            :sign="getSign(38)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(38)._id)"
          />
          <ClassSign
            :sign="getSign(53)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(53)._id)"
          />
          <ClassSign
            :sign="getSign(57)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(57)._id)"
          />
          <ClassSign
            :sign="getSign(60)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(60)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(8)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(8)._id)"
          />
          <ClassSign
            :sign="getSign(11)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(11)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(34)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(34)._id)"
          />
          <ClassSign
            :sign="getSign(37)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(37)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(54)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(54)._id)"
          />
          <ClassSign
            :sign="getSign(58)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(58)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(9)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(9)._id)"
          />
          <BlankClass />
          <BlankClass />
          <ClassSign
            :sign="getSign(35)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(35)._id)"
          />
          <ClassSign
            :sign="getSign(42)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(42)._id)"
          />
          <ClassSign
            :sign="getSign(45)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(45)._id)"
          />
          <ClassSign
            :sign="getSign(55)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(55)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(13)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(13)._id)"
          />
          <ClassSign
            :sign="getSign(16)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(16)._id)"
          />
          <ClassSign
            :sign="getSign(18)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(18)._id)"
          />
          <ClassSign
            :sign="getSign(39)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(39)._id)"
          />
          <ClassSign
            :sign="getSign(43)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(43)._id)"
          />
          <ClassSign
            :sign="getSign(46)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(46)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(14)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(14)._id)"
          />
          <ClassSign
            :sign="getSign(17)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(17)._id)"
          />
          <BlankClass />
          <ClassSign
            :sign="getSign(40)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(40)._id)"
          />
          <ClassSign
            :sign="getSign(44)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(44)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(15)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(15)._id)"
          />
          <ClassSign
            :sign="getSign(22)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(22)._id)"
          />
          <ClassSign
            :sign="getSign(25)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(25)._id)"
          />
          <ClassSign
            :sign="getSign(41)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(41)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(19)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(19)._id)"
          />
          <ClassSign
            :sign="getSign(23)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(23)._id)"
          />
          <ClassSign
            :sign="getSign(26)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(26)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(20)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(20)._id)"
          />
          <ClassSign
            :sign="getSign(24)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(24)._id)"
          />
        </div>

        <div class="tableArea w-full flex justify-center">
          <ClassSign
            :sign="getSign(21)"
            :c1="c1"
            :c2="c2"
            :c3="c3"
            :lang="lang.abbreviation"
            @click="exploreClass(getSign(21)._id)"
          />
        </div>
      </div>

      <div v-else class="w-full h-full flex justify-center items-center">
        <DotLoading dotColor="#25899b" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import panzoom from "panzoom";

import DotLoading from "@/components/DotLoading";
import ClassSign from "@/components/ClassSign";
import BlankClass from "@/components/BlankClass";

export default {
  name: "SixtySixClasses",
  components: {
    DotLoading,
    ClassSign,
    BlankClass
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: true,
      page: null,
      langs: null
    };
  },
  computed: {
    ...mapState({
      classes: state => state.Classes.list,
      lang: state => state.Settings.activeLanguage,
      phases: state => state.Settings.phases,
      periods: state => state.Settings.periods,
      glossary: state => state.Glossary.list,
      selectedEntry: state => state.Glossary.selectedEntry,
      c1: state => state.Settings.c1,
      c2: state => state.Settings.c2,
      c3: state => state.Settings.c3
    }),

    orderedClasses() {
      return [...this.classes].sort((a, b) => a.number - b.number);
    }
  },
  watch: {},
  async created() {},
  async mounted() {
    if (this.classes.length < 66) {
      await this.$store.dispatch("Classes/list");
    }

    this.loading = false;

    var body = document.querySelector("body");
    body.classList.add("preventRefresh");

    var element = document.querySelector("#table66");

    const width = document.getElementById("app").offsetWidth;
    const height = document.getElementById("table66").offsetHeight;

    panzoom(element, {
      onTouch: function() {
        return false;
      }
    }).zoomAbs(
      width > 900 ? width / 5 : width / 10, // initial x position
      width > 900 ? height * 0.1 : height * 0.3, // initial y position
      width > 900 ? 0.5 : 0.2 // initial zoom
    );

    if (this.$route.params.id) {
      const active = this.classes.find(
        item =>
          item._id == this.$route.params.id ||
          item.number == this.$route.params.id
      );
      if (active) {
        this.$store.commit("Classes/SET_ACTIVE", active._id);
      }
    }
  },
  beforeDestroy() {
    var body = document.querySelector("body");
    body.classList.remove("preventRefresh");
  },
  methods: {
    getSign(signNumber) {
      return this.classes.find(sign => sign.number == signNumber);
    },
    exploreClass(signId) {
      this.$router.push({
        path: this.$route.path,
        query: {
          classSign: signId
        }
      });
    }
  }
};
</script>

<style lang="scss">
.preventRefresh {
  overscroll-behavior: contain;
}

.tableArea {
  min-width: 1600px;
  width: 1600px;
  position: relative;
}

.point1 {
  top: 35px;
  left: -10px;
}

.point2 {
  top: 35px;
  right: -30px;
}

.point3 {
  bottom: 0;
}

.arrow {
  position: absolute;
  opacity: 0.66;

  &:hover {
    opacity: 1;
  }

  &.periods {
    font-size: 28px;
    font-weight: bold;
    color: white;
    transform: rotate(64deg);
    text-align: center;
    padding-top: 13px;
    border-top: 4px solid #fff;
    letter-spacing: 1px;

    &::after {
      content: "";
      position: absolute;
      top: -10px;
      right: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 40px solid #fff;
    }

    &.arrow-grounding {
      top: 280px;
      left: -118px;
      width: 330px;
    }
    &.arrow-presentation {
      top: 619px;
      left: 25px;
      width: 370px;
    }
    &.arrow-representing {
      top: 982px;
      left: 185px;
      width: 400px;
    }
    &.arrow-communicating {
      top: 1376px;
      left: 360px;
      width: 430px;
    }
  }

  &.phases {
    font-size: 28px;
    font-weight: bold;
    color: white;
    transform: rotate(-63deg);
    border-top: 4px solid #fff;
    text-align: center;
    padding-top: 10px;
    letter-spacing: 1px;

    &:not(.down):after {
      content: "";
      position: absolute;
      top: -10px;
      right: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 40px solid #fff;
    }

    &.down {
      &::after {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 40px solid #fff;
      }
    }

    &.arrow-perceiving {
      top: 1360px;
      right: 389px;
      width: 400px;
    }
    &.arrow-imagining {
      top: 1360px;
      right: 289px;
      width: 400px;
    }

    &.arrow-experiencing {
      top: 973px;
      right: 186px;
      width: 400px;
    }
    &.arrow-producing {
      top: 973px;
      right: 86px;
      width: 400px;
    }

    &.arrow-understanding {
      top: 592px;
      right: -10px;
      width: 400px;
    }
    &.arrow-deliberating {
      top: 592px;
      right: -110px;
      width: 400px;
    }

    &.arrow-sharing {
      top: 253px;
      right: -149px;
      width: 330px;
    }
    &.arrow-persuading {
      top: 253px;
      right: -249px;
      width: 330px;
    }
  }
}
</style>
