<template>
  <div
    class="rounded p-1 relative classSign m-1 pb-3 flex-shrink-0 flex items-center justify-center"
    style="background-color: transparent"
  ></div>
</template>

<script>
export default {
  name: "BlankSign",
  components: {},
  filters: {},
  props: {
    sign: Object
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {},
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.classSign {
  width: 120px;
  height: 120px;
}
</style>
